import {NumericFormat} from "react-number-format";
import React from "react";
import moment from "moment";
import {setCheckedInputs} from "../redux/reducers/sharedBusiness";
import {useDispatch} from "react-redux";


const SharedBusinessList = ({state, onRefresh}) => {
    const dispatch = useDispatch()
    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]
    const allCheckHandler = (e) => {
        const checkNos = []
        for(let index = 0; index < state.memberList.length; index++) {
            checkNos.push(state.memberList[index].no)
        }
        dispatch(setCheckedInputs(e.target.checked? checkNos : []));

    }
    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, no]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== no)));
        }
    }

    function getOverlapCount(overlaps) {
        let count = 0;

        for(const overlap of overlaps) {
            count += overlap.overlap_count
        }
        return count
    }

    return <div>
        <div className="content border excel_body">
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        {<th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>}
                        <th style={{width: "8%"}}>번호</th>
                        <th>DB명</th>
                        <th>원본파일명</th>
                        <th>지점</th>
                        <th>분배자</th>
                        <th>담당자</th>
                        <th>메모</th>
                        <th>수신상태</th>
                        <th>연락처</th>
                        <th>회수여부</th>
                        <th>중복횟수</th>
                        <th>지점분배일</th>
                        <th>담당자배정일</th>
                        <th>수신상태수정일</th>

                    </tr>
                    </thead>
                    {state.memberList && state.memberList.map((file, index) => (
                        <tbody key={file.no}>
                        <tr style={file.businesses.length > 0? {
                            backgroundColor: file.businesses[0]?.receive === 1? "#FFFFFF" : receiveList[file.businesses[0].receive - 1].color + "33"
                        } : {
                            backgroundColor: "#FFD9FA33"
                        }}>
                            {<td>
                                <input className="form-check-input" type="checkbox"
                                       checked={state.checkedInputs.includes(file.no)}
                                       onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                            </td>}
                            <td style={{fontSize: "13px"}}>{file.no}</td>
                            <td>{file.businessDBTitle.title}</td>
                            <td>{file.member_upload.file_name}</td>
                            <td>{file.admin.store.name}</td>
                            <td>{file.admin.id}</td>
                            <td>{file.businesses.length > 0? file.businesses[0].admin.id : "-"}</td>
                            <td>{file.businesses.length > 0? file.businesses[0].memo : "-"}</td>
                            <td
                                style={file.businesses.length > 0? {
                                    color: receiveList[file.businesses[0].receive - 1].color
                                }:{}}
                            >{file.businesses.length > 0? receiveList[file.businesses[0].receive -1].name : "-"}
                            </td>
                            <td>{file.member_upload.phone}</td>
                            <td>{file.is_return? "회수" : "할당"}</td>
                            <td>{file.member_upload.overlaps.length > 0? getOverlapCount(file.member_upload.overlaps): 0}</td>
                            <td>{moment(file.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{file.businesses.length > 0? moment(file.businesses[0].createdAt).format("YYYY-MM-DD HH:mm") : "-"}</td>
                            <td>{file.businesses.length > 0? file.businesses[0].createdAt !== file.businesses[0].updatedAt? moment(file.businesses[0].updatedAt).format("YYYY-MM-DD HH:mm") : "-" : "-"}</td>

                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    </div>


}

export default SharedBusinessList