import {call, put,} from "redux-saga/effects";
import {fetchBusinessDBList, fetchDBAdmins} from "../../lib/api";
import {failedGetBusinessDBList, failedGetDBAdmins, setBusinessDBList, setDBAdmins} from "../reducers/businessDB";

export function* getBusinessDBListSaga(action) {
    try {
        const res = yield call(
            fetchBusinessDBList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.titleNo,
            action.payload.startDate,
        )
        yield put(setBusinessDBList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetBusinessDBList(error));
    }
}

export function* getDBAdminsSaga(action) {
    try {
        const res = yield call(
            fetchDBAdmins,
            action.payload.store,
        )
        yield put(setDBAdmins(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetDBAdmins(error));
    }
}
