import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export const businessDBSlice = createSlice({
    name: 'businessDB',
    initialState: {
        isLoading: false,
        isAdminLoading: false,
        isUploadLoading: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        memberList: [],
        checkedInputs: [],
        error: null,
        adminError: null,
        uploadError: null,
        query: "",
        stores: [],
        store: 0,
        admins: [],
        admin: 0,
        year: moment().year(),
        month: moment().month(),
        titles: [],
        titleNo: 0,
        blackCount: 0,
        shareType: 0,
        startDate: "",
    },
    reducers: {
        getBusinessDBList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
            state.titleNo = action.payload.titleNo
            state.shareType = action.payload.shareType
            state.startDate = action.payload.startDate
        },
        setBusinessDBList(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.blackCount = action.payload.blackCount;
            state.memberList = action.payload.memberList;
            state.stores = action.payload.stores;
            state.titles = action.payload.titles;
        },
        failedGetBusinessDBList(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        getDBAdmins(state, action) {
            state.isAdminLoading = true
            state.store = action.payload.store
        },
        setDBAdmins(state, action) {
            state.isAdminLoading = false
            state.admins = action.payload.admins;
        },
        failedGetDBAdmins(state, action) {
            state.isAdminLoading = false;
            state.adminError = action.payload
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
    },
});

export const {
    getBusinessDBList, setBusinessDBList, failedGetBusinessDBList,
    getDBAdmins, setDBAdmins, failedGetDBAdmins,
    setCheckedInputs
} = businessDBSlice.actions;

export default businessDBSlice.reducer