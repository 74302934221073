import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";
import React, {useEffect, useState} from "react";
import {setCheckedInputs} from "../redux/reducers/businessDB";
import axios from "axios";
import moment from "moment";
import {getDBAdmins} from "../redux/reducers/businessDB";


const BusinessDBList = ({state, onRefresh}) => {

    const dispatch = useDispatch()
    const [sendType, setSendType] = useState(0);

    const [teamLeader, setTeamLeader] = useState(0);
    const [titleNo, setTitleNo] = useState(0);
    const [sendTitle, setSendTitle] = useState("");

    const userInfo = useSelector((state) => state.userInfo);


    const allCheckHandler = (e) => {
        const checkNos = []
        for(let index = 0; index < state.memberList.length; index++) {
            checkNos.push(state.memberList[index].no)
        }
        dispatch(setCheckedInputs(e.target.checked? checkNos : []));

    }
    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, no]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== no)));
        }
    }

    const sendBusinessDBList = (e) => {
        if (teamLeader !== 0) {
            if(state.checkedInputs.length > 0) {
                if(sendTitle !== "") {
                    axios.post('/admin/businessDBList/sendBusinessDB', {
                        sendDBNos: state.checkedInputs,
                        sendTitle: sendTitle,
                        admin_no: teamLeader
                    }).then((res) => {
                        if (res.data.isUpdated) {
                            alert("전송완료")
                            window.location.reload();
                        } else if(res.data.error) {
                            alert(res.data.error)
                        } else {
                            alert("전송실패")
                        }
                    })
                }
                else {
                    alert("제목을 입력해주세요")
                }

            }
            else {
                alert("선택된 DB가 없습니다.")
            }


        } else {
            alert("관리자 선택해주세요")
        }
    }
    const sendBusinessDBListByFileName = () => {
        if (teamLeader !== 0) {
            if(titleNo !== 0) {
                axios.post('/admin/businessDBList/sendBusinessDBByTitle', {
                    titleNo: titleNo,
                    adminNo: teamLeader
                }).then((res) => {
                    if (res.data.isUpdated) {
                        alert("전송완료")
                        window.location.reload();
                    } else if(res.data.error) {
                        alert(res.data.error)
                    } else {
                        alert("전송실패")
                    }
                })
            }
            else {
                alert("선택된 목록이 없습니다.")
            }


        } else {
            alert("관리자 선택해주세요")
        }
    }

    const gradeState = (level) => {
        switch(level) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "최고관리자"
            default : return level
        }
    }

    const onStoreHandler = (e) => {
        dispatch(getDBAdmins({
            store: e.currentTarget.value
        }))
    }
    const onTeamLeaderHandler = (e) => {
        setTeamLeader(e.currentTarget.value)
    }
    const onSendTitle = (e) => {
        setSendTitle(e.currentTarget.value)
    }

    const deleteHandler = () => {
        if(state.checkedInputs.length > 0) {
            axios.post('/admin/business_upload/delete', {
                fileNos: state.checkedInputs
            }).then((res) => {
                console.log(res.data)
                if(res.data.isDeleted) {
                    window.location.reload();
                }
                else {
                    alert('실패')
                }
            })
        }
        else {
            alert("선택된게 없습니다.")
        }
    }

    function getOverlapCount(overlaps) {
        let count = 0;

        for(const overlap of overlaps) {
            count += overlap.overlap_count
        }
        return count
    }

    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    return <div>
        <div className="d-flex" style={{
            width: "100%"
        }}>
            <div style={{
                width: "10%"
            }}>
                <button className="btn btn-sm btn-danger mx-1" onClick={deleteHandler}>서버 삭제</button>
            </div>

            <div className="d-flex justify-content-end" style={{
                width: "90%"
            }}>
                {userInfo.grade >= 3 && (
                    <>
                        <select className="form-select" onChange={onStoreHandler} value={state.store} style={{width:"25%"}}>
                            <option key={0} value={0}>---영업점선택---</option>
                            {state.stores.map((item, index) => (
                                <option key={item.no} value={item.no}>{item.name}</option>
                            ))}
                        </select>
                        <select className="form-select" onChange={onTeamLeaderHandler} value={teamLeader} style={{width:"25%"}}>
                            <option key={0} value={0}>---팀장선택---</option>
                            {state.admins? state.admins.map((item, index) => (
                                    <option key={item.no} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                                ))
                                : <></> }
                        </select>
                        <select className="form-select" onChange={(e) => {
                            setSendType(parseInt(e.currentTarget.value))
                        }} value={sendType} style={{width:"150px"}}>
                            <option key={0} value={0}>선택전송</option>
                            <option key={1} value={1}>파일전송</option>
                        </select>
                    </>
                )}

                {sendType === 0?
                    <>
                        <input className="form-control border border-dark" placeholder="제목" maxLength="12" type="text" style={{width: "130px"}}
                               onChange={onSendTitle} />
                        <button className="btn btn btn-primary mx-1" style={{width:"80px"}}
                                onClick={sendBusinessDBList}>선택전송
                        </button>
                    </>
                    : <>
                        <select className="form-select" onChange={(e) => {
                            setTitleNo(parseInt(e.currentTarget.value))
                        }} value={titleNo} style={{width: "300px"}}>
                            <option key={0} value={0}>모든목록</option>
                            {state.titles.map((item) => (
                                <option key={item.no} value={item.no}>{`${item.title}(${item.admin.id})`}</option>
                            ))}
                        </select>
                        <button className="btn btn-sm btn-primary mx-1" style={{width:"80px"}} onClick={sendBusinessDBListByFileName}>파일전송</button>
                    </>
                }


            </div>
        </div>

        <div className="content border excel_body">
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        <th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>
                        <th style={{width: "8%"}}>번호</th>
                        <th>파일명</th>
                        <th>연락처</th>
                        <th>중복횟수</th>
                        <th>분배여부</th>
                        <th>마지막수신상태</th>
                        <th>할당횟수</th>
                        <th>등록일</th>
                        <th>수정일</th>
                    </tr>
                    </thead>
                    <tbody key={"list_tbody"}>

                    {state.memberList && state.memberList.map((file, index) => (
                        <>
                            <tr>
                                <td>
                                    <input className="form-check-input" type="checkbox"
                                           checked={state.checkedInputs.includes(file.no)}
                                           onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                                </td>
                                <td style={{fontSize: "13px"}}>{file.no}</td>
                                <td>{file.file_name}</td>
                                <td>{file.phone}</td>
                                <td>{file.overlaps.length > 0? getOverlapCount(file.overlaps): 0}</td>
                                <td>{file.is_used? "분배중" : "미분배"}</td>
                                <td>{
                                    file.businessDBLists.length > 0?
                                        file.businessDBLists[0].businesses.length > 0? `${receiveList[file.businessDBLists[0].businesses[0].receive -1].name} (${file.businessDBLists[0].businesses[0]?.admin?.id})`: "담당지정없음"
                                        : "분배된적없음"
                                }</td>
                                <td>{
                                    file.businessDBLists !== undefined?
                                        file.businessDBLists.length
                                        : 0
                                }</td>
                                <td>{moment(file.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                <td>{moment(file.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                            </tr>
                        </>
                    ))}

                    </tbody>
                </table>
            </div>
        </div>
    </div>


}

export default BusinessDBList